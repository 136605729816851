<template>
  <section>
    <div class="content-header">
      <h2>Learning environment, interaction, sense of belonging and study success in ethnically diverse student groups </h2>
    </div>
    <div class="content-wrapper">
      <p>What is the link between the learning environment, interaction, sense of belonging and study success?</p>
      <p>Is the link the same for both ethnic minority students and ethnic majority students?</p>
      <p><a href="https://link.springer.com/article/10.1007%2Fs11162-010-9168-1" target="_blank">This paper</a> shows that ethnic minority students appeared to feel at home in their educational program if they had a good formal relationship with teachers and fellow students. However, ethnic minority students’ sense of belonging to the institution <strong>did not</strong> contribute to their study progress. </p>
      <p>On the other hand, in majority students, informal relationships with fellow students were what led to a sense of belonging. In these students, the sense of belonging did further academic progress.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
